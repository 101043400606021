import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/layout'

const Success = () => (
  <Layout>
    <h1>Hi from Buster!</h1>
    <p>Your message has been submitted.</p>
    <p>
      Thank you for reaching out. I will respond as soon as I am able. Have a
      great day!
    </p>
    <Link to="/">Go back to the homepage</Link>
  </Layout>
)

export default Success
